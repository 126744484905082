<template>
  <div>
    <h3 class="flix-html-h3">{{ $t('message.bookingMethod') }}</h3>
    <div class="flix-form-group">
      <select class="flix-form-control" v-model="bookingMethod" @change="setBookingMethod">
        <option value="default">{{ $t('message.verifyMethod') }}</option>
        <option value="noVerify">{{ $t('message.noVerify') }}</option>
      </select>
    </div>
    <div class="flix-form-group" v-if="bookingMethod === 'noVerify'">
      <div class="flix-text-danger">
        <flixIcon id="warning-sign" /> {{ $t('message.bookingMethodHint') }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  props: { data: Object, callback: Function },
  data () {
    return {
      bookingMethod: this.getBookingMethod()
    }
  },
  watch: {
  },
  methods: {
    getBookingMethod () {
      if (typeof this.data.bookingMethod === 'undefined' || !this.data.bookingMethod) {
        return 'default'
      }
      return this.data.bookingMethod
    },
    setBookingMethod () {
      this.callback(this.bookingMethod)
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
